<fuse-loading-bar></fuse-loading-bar>

<fuse-vertical-navigation
        [mode]="isScreenSmall ? 'over' : 'side'"
        [name]="'mainNavigation'"
        [navigation]="filteredNavigation"
        [opened]="!isScreenSmall"
        class="dark bg-gray-900 print:hidden">
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <div class="flex items-center justify-center">
                <img class="w-8" src="../../../assets/images/logo/logo.svg" alt="">
            </div>
            <div class="flex items-center ml-auto">
                <!--                <notifications></notifications>-->
            </div>
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4">
            <div class="relative w-24 h-24">
                <img [src]="profilePic || userPlaceholder" alt="User avatar" class="w-full h-full rounded-full">
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div
                        class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user.firstName}}
                </div>
                <div
                        class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{user.email}}
                </div>
            </div>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<div class="flex flex-col flex-auto w-full min-w-0">
    <div *ngIf="autoLogin" class="flex py-1 px-6 bg-green-600 text-white justify-between">
        <span class="py-1 text-white">{{ 'merchantUser.autoLoginMsg' | transloco:{userName: userName} }}</span>
        <span matRipple [matRippleCentered]="true" (click)="logoutFromAutoLogin()"
              class="px-4 py-1 cursor-pointer hover:bg-green-700 hover:bg-opacity-20 rounded border border-green-200">{{ 'merchantUser.autoLoginButtonText' | transloco }}</span>
    </div>
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <button (click)="toggleNavigation('mainNavigation')" mat-icon-button>
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <branch-filter></branch-filter>
            <languages></languages>
            <currencies></currencies>
            <notifications></notifications>
            <user [showAvatar]="true"></user>
            <!--            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>-->
            <!--            <search [appearance]="'bar'"></search>-->
            <!--            <shortcuts></shortcuts>-->
            <!--            <messages></messages>-->
        </div>
    </div>


    <div class="flex flex-col flex-auto">
        <router-outlet *ngIf="true"></router-outlet>
    </div>
</div>
