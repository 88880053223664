<ng-container *transloco="let t; read: translationScope;">
    <mat-form-field *ngIf="formGroup && formGroup.get(this.key)" [class.form-field-border-none]="readOnly"
                    [formGroup]="formGroup" [subscriptSizing]="'dynamic'"
                    class="w-full no-label mat-toggle {{classes}}" floatLabel="auto">
        <mat-slide-toggle (click)="readOnly ? clicked($event) : null" [color]="color" [formControlName]="key"
                          [matTooltipPosition]="tooltipPosition"
                          [matTooltip]="titleDisplayMode == 'tooltip' ? (title ? title : t(translationLabel)) : tooltipText" class="mt-8 sm:mt-0">
            <span *ngIf="titleDisplayMode == 'label'"
                  [hidden]="!showLabel">{{ title ? title : t(translationLabel) }}</span>
        </mat-slide-toggle>
        <input [readonly]="readOnly" class="hidden" hidden matInput>
        <mat-error *ngIf="formGroup.get(key)?.errors?.required">
            {{'validation.message.required' | transloco:{field: title ? title : t(translationLabel)} }}
        </mat-error>
    </mat-form-field>
</ng-container>
