export class CommonEvents {
    public static CURRENT_USER_LOADED = 'CURRENT_USER_LOADED';
    public static CURRENT_BRANCH_CHANGED = 'CURRENT_BRANCH_CHANGED';
    public static LANGUAGE_CHANGED = 'LANGUAGE_CHANGED';
    public static PROFILE_DETAILS_UPDATED = 'PROFILE_DETAILS_UPDATED';
    public static NOTIFICATIONS_CHANGED = 'NOTIFICATIONS_CHANGED';
    public static ALL_NOTIFICATIONS_CHANGED = 'ALL_NOTIFICATIONS_CHANGED';

    public static dispatchEvent(eventType: string, data: any) {
        window.dispatchEvent(new CustomEvent(eventType, {detail: data}));
    }
}
