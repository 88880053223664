import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IconLib} from "@moodeon-commons/model/icon-lib.ts";
import {ThemePalette} from "@angular/material/core";

@Component({
    template: ''
})
export abstract class BaseButtonComponent {
    @Input()
    iconLib: IconLib = 'mat';

    @Input()
    icon: string;

    @Input()
    disabled = false;

    @Input()
    iconClass = 'icon-size-6';

    @Input()
    buttonClass = '';

    @Input()
    color: ThemePalette;

    @Input()
    type: 'raised' | 'stroked' | 'flat' | 'icon' | '' | undefined | null;

    @Input()
    iconColor: string;

    @Output()
    buttonClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
}
