import {BaseFilter} from "@moodeon-commons/model/common";
import {BaseModel} from "@moodeon-commons/model/base-model";
import {ContactPhone} from "@app/model/merchant";
import {Lookup} from "@app/model/lookup";
import {ActionType, RoleListItemResponse} from "@app/model/role";
import {BranchListItemResponse} from "@app/model/branch";

export class MerchantUserListItemResponse extends BaseModel {
    profilePic: string;
    name: string;
    email: string;
    username: string;
    role: RoleListItemResponse;
    branch: string;
    status: Lookup;
}

export class MerchantUserResponse extends BaseModel {
    profilePic: string;
    coverPic: string;
    firstName: string;
    middleNames: string;
    lastName: string;
    email: string;
    username: string;
    primaryContact: ContactPhone;
    role: RoleListItemResponse;
    branch: BranchListItemResponse;
    status: string;
}

export class MerchantUserFilter extends BaseFilter {
    roleId: number;
    status: string;
}

export class CurrentUser extends BaseModel {
    profilePic: string;
    firstName: string;
    middleNames: string;
    lastName: string;
    email: string;
    username: string;
    grants: { [key: string]: ActionType[] };
    branch: BranchListItemResponse;
    baseCurrency: string;
    defaultLanguage: string;
    status: string;
}
