<!-- Button -->
<button [matMenuTriggerFor]="languages" mat-button>
    <span class="flex items-center mat-ripple">
        <span class="relative w-6 rounded-sm overflow-hidden">
            <img [alt]="'Flag image for ' + activeLang?.flag" [src]="imgUrl" class="w-full">
        </span>
        <span class="ml-3">{{activeLang?.code}}</span>
    </span>
</button>

<!-- Language menu -->
<mat-menu
    #languages="matMenu"
    [xPosition]="'before'">
    <ng-container *ngFor="let lang of availableLangs; trackBy: trackByFn">
        <button
                (click)="setActiveLang(lang)"
                mat-menu-item>
            <span class="flex items-center">
                <span class="relative w-6 rounded-sm overflow-hidden">
                    <img
                            [alt]="'Flag image for ' + lang.flag"
                            class="w-full"
                            src="https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/{{lang.flag}}.svg">
                </span>
                <span class="ml-3">{{lang.name}}</span>
            </span>
        </button>
    </ng-container>
</mat-menu>

