import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToggleInputComponent} from "@moodeon-commons/component/input/toggle-input/toggle-input.component";
import {BaseComponent} from "@moodeon-commons/component/base-component";
import {takeUntil} from "rxjs";
import {AlertService} from "@moodeon-commons/service/alert.service";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";
import {BranchService} from "@app/service/branch.service";
import {BranchListItemResponse, PatchBranchDataRequest} from "@app/model/branch";
import {translate} from "@ngneat/transloco";

@Component({
    selector: 'store-open-toggle',
    standalone: true,
    imports: [CommonModule, ToggleInputComponent],
    templateUrl: './store-open-toggle.component.html',
    styles: []
})
export class StoreOpenToggleComponent extends BaseComponent {
    branch: BranchListItemResponse;

    constructor(private alertService: AlertService, private branchService: BranchService) {
        super();
    }

    ngOnInit() {
        this.branch = LocalStorageHandler.CURRENT_BRANCH.get();
    }

    toggleOpenStatus(storeOpen: boolean) {
        this.branch.open = storeOpen;
        this.branchService.patchBranchData(this.branch.id, {open: storeOpen} as PatchBranchDataRequest).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => {
                this.alertService.showSuccessAlert(translate((storeOpen ? 'branch.openMsg' : 'branch.closedMsg'), {name: this.branch.name}));
                LocalStorageHandler.CURRENT_BRANCH.set(this.branch);
            })
    }
}
